export function parseShippingAddress(address) {
  if (!address) {
    return null;
  }

  const address1 = address.addressLine[0];
  const address2 = address.addressLine[1];
  const city = address.city;
  const state = address.region;
  const postal_code = address.postalCode;
  const country = address.country;

  return {
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    postal_code: postal_code,
    country: country,
  };
}

export async function setupPaymentRequest(params) {
  console.log("setupPaymentRequest params", params);
  const stripe = Stripe(process.env.STRIPE_PUBLIC_KEY);

  console.log("stripe.paymentRequest");

  const request_shipping = params.request_address;

  const required_billing_contact_fields = params.request_address
    ? ["postalAddress"]
    : [];

  const shipping_options = params.request_address
    ? [
        {
          id: "free-shipping",
          label: "Mailing Address",
          detail: "Address for paper statements",
          amount: 0,
        },
      ]
    : [];

  paymentRequest = stripe.paymentRequest({
    country: "US",
    currency: "usd",
    total: {
      label: "Church",
      amount: 1000,
    },
    requestPayerName: true,
    requestPayerEmail: true,
    requestShipping: request_shipping,
    shippingOptions: shipping_options,
    requiredBillingContactFields: required_billing_contact_fields,
  });

  paymentRequest.on("token", function (ev) {
    console.log("ev", ev);
    const name_array = ev.payerName.split(/\s+/);
    const first_name = name_array.slice(0, -1).join(" ");
    const last_name = name_array.pop();

    const shipping_address = parseShippingAddress(ev.shippingAddress);

    const stripe_payment_token_type = `payment_request_${ev.token.type}`;
    const email = ev.payerEmail;
    const token = ev.token.id;

    const success_response = {
      first_name: first_name,
      last_name: last_name,
      stripe_payment_token_type: stripe_payment_token_type,
      email: email,
      token: token,
      address: shipping_address,
    };

    const childWindow = document.getElementById(MERLIN_IFRAME).contentWindow;

    postRobot.send(childWindow, "paymentRequestResponse", success_response);

    ev.complete("success");
  });

  let can_make_payment;

  try {
    const result = await paymentRequest.canMakePayment();
    console.log("canMakePayment", result);
    if (result) {
      can_make_payment = true;
      console.log("can_make_payment true");

      const childWindow = document.getElementById(MERLIN_IFRAME).contentWindow;
      postRobot.send(childWindow, "paymentRequestAvailable", result);
    } else {
      can_make_payment = false;
      console.log("can_make_payment false");
    }
  } catch (error) {
    console.error("Error in canMakePayment:", error);
  }

  return can_make_payment;
}
