import { version as VERSION } from "../../version";
import postRobot from "post-robot";
import Honeybadger from "@honeybadger-io/js";
import {
  appendScript,
  baseMerlinElement,
  buildFullConfig,
  displayIframeOrCreateIframe,
  fetchConfigSettings,
  getQueryParamsSettings,
  loadIframe,
  MERLIN_IFRAME,
  merlinData,
  resetParentViewportSettings,
  setupEhopeOrgButton,
  shouldOpenFirstMerlinImmediately,
  storeParentViewportSettings,
} from "./parent-utils";
import { parseShippingAddress } from "./payment-request-utils";

// MerlinState class to manage all state
class MerlinState {
  constructor() {
    this._stripe_v3_loaded = false;
    this._can_make_payment = false;
    this._payment_request = null;
    this._transaction_successful = false;
    this._preloaded_merlin_key = null;
    this._iframe_closed = false;
    this._merlin_iframe_ready_promise = null;
    this._merlin_iframe_ready_promise_resolve = null;
    this._payment_request_ready_promise = null;
    this._payment_request_ready_promise_resolve = null;
    
    this.ACCOUNTS_FOR_APPLE_PAY_ADDRESS = [
      "account_01GF3SDDZA9X684D11FN4FRM7D",
      "account_01GTA2MG39FHK1AW5XAERVSZXT",
    ];

    this.createPaymentRequestReadyPromise();
    this.createMerlinIframeReadyPromise();
  }

  // Promise management
  createPaymentRequestReadyPromise() {
    this._payment_request_ready_promise = new Promise((resolve) => {
      this._payment_request_ready_promise_resolve = resolve;
    });
    return this._payment_request_ready_promise;
  }

  resolvePaymentRequestReady() {
    if (this._payment_request_ready_promise_resolve) {
      this._payment_request_ready_promise_resolve();
    }
  }

  createMerlinIframeReadyPromise() {
    this._merlin_iframe_ready_promise = new Promise((resolve) => {
      this._merlin_iframe_ready_promise_resolve = resolve;
    });
    return this._merlin_iframe_ready_promise;
  }

  resolveMerlinIframeReady() {
    if (this._merlin_iframe_ready_promise_resolve) {
      this._merlin_iframe_ready_promise_resolve();
    }
  }

  resetMerlinIframeReadyPromise() {
    return this.createMerlinIframeReadyPromise();
  }

  // Getters and setters
  get stripeV3Loaded() { return this._stripe_v3_loaded; }
  set stripeV3Loaded(value) { 
    this._stripe_v3_loaded = value; 
  }

  get canMakePayment() { return this._can_make_payment; }
  set canMakePayment(value) { this._can_make_payment = value; }

  get paymentRequest() { return this._payment_request; }
  set paymentRequest(value) { this._payment_request = value; }

  get transactionSuccessful() { return this._transaction_successful; }
  set transactionSuccessful(value) { this._transaction_successful = value; }

  get preloadedMerlinKey() { return this._preloaded_merlin_key; }
  set preloadedMerlinKey(value) { this._preloaded_merlin_key = value; }

  get iframeWasClosed() { return this._iframe_closed; }
  set iframeWasClosed(value) { this._iframe_closed = value; }

  getMerlinIframeReadyPromise() {
    return this._merlin_iframe_ready_promise;
  }

  getPaymentRequestReadyPromise() {
    return this._payment_request_ready_promise;
  }

  // Reset state
  reset() {
    this._stripe_v3_loaded = false;
    this._can_make_payment = false;
    this._payment_request = null;
    this._transaction_successful = false;
    this._preloaded_merlin_key = null;
    this._iframe_closed = false;
    this.resetMerlinIframeReadyPromise();
  }
}

// Create singleton instance
const merlinState = new MerlinState();

(function () {
  
  // Move openMerlin function definition up here, before it's used
  async function openMerlin(data) {
    const iframe = document.getElementById(MERLIN_IFRAME);
    if (!iframe) {
      throw new Error("Iframe not found when trying to open Merlin");
    }
    
    try {
      await postRobot.send(iframe, "MERLIN_CONFIG", { config: data });
      await displayIframeOrCreateIframe();
      return true;
    } catch (err) {
      console.error("Error in openMerlin:", err);
      throw err;
    }
  }

  function writeHeadStyles() {
    const parent_css = "body.modal-open { overflow: hidden !important; position: fixed !important; width: 100vw }";
    const parent_style_element = document.createElement("style");
    parent_style_element.textContent = parent_css;
    document.head.appendChild(parent_style_element);
  }

  function redirectToHttps() {
    if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
      window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }
  }

  if (window.merlinInstallScriptExecuted) {
    return;
  }

  window.merlinInstallScriptExecuted = true;
  console.log("installScript", VERSION);

  redirectToHttps();
  setupEhopeOrgButton();
  storeParentViewportSettings();

  // Event listeners
  try {
    postRobot.on("IFRAME_PAYMENT_REQUEST_READY", () => {
      merlinState.resolvePaymentRequestReady();
    });
    postRobot.on("MERLIN_IFRAME_READY", (event) => {
      console.log("MERLIN_IFRAME_READY received from iframe", event);
      merlinState.resolveMerlinIframeReady();
      console.log("MERLIN_IFRAME_READY resolved");
    });
  } catch (error) {
    console.error("Error setting up postRobot listeners:", error);
  }

  async function initializeMerlin() {
    console.time("FINAL_LOAD");
    
    try {
      const query_params = getQueryParamsSettings();
      
      await setupClickHandlers(query_params);
      
      appendScript("https://js.stripe.com/v2/");
      
      writeHeadStyles();

      const base_merlin_element = baseMerlinElement();
      
      const open_immediately = shouldOpenFirstMerlinImmediately();

      try {
        await loadUpIframeSettings(base_merlin_element, query_params, open_immediately);
        console.timeEnd("FINAL_LOAD");
      } catch (error) {
        console.error("Failed to initialize Merlin:", error);
      }
    } catch (error) {
      console.error("Critical initialization error:", error);
    }
  }

  // Check the state of both load and DOMContentLoaded
  
  if (document.readyState === 'complete') {
    // Both DOMContentLoaded and load have fired
    initializeMerlin();
  } else if (document.readyState === 'interactive') {
    initializeMerlin();
  } else {
    // Neither event has fired, attach to DOMContentLoaded
    document.addEventListener('DOMContentLoaded', function() {
      initializeMerlin();
    });
  }

  async function loadStripeV3() {
    if (merlinState.stripeV3Loaded && window.stripe) {
      return window.stripe;
    }
    
    return new Promise((resolve, reject) => {
      const stripe_script = document.createElement("script");
      stripe_script.src = "https://js.stripe.com/v3/";
      stripe_script.async = true;
      
      stripe_script.onload = () => {
        if (window.Stripe) {
          try {
            merlinState.stripeV3Loaded = true;
            // Create and cache the Stripe instance
            window.stripe = window.Stripe(process.env.STRIPE_PUBLIC_KEY)
            resolve(window.stripe);
          } catch (err) {
            console.error("Error initializing Stripe:", err);
            reject(err);
          }
        } else {
          const error = new Error("Stripe v3 failed to load");
          console.error(error);
          reject(error);
        }
      };
      
      stripe_script.onerror = (err) => {
        console.error("Failed to load Stripe v3 script:", err);
        reject(new Error("Failed to load Stripe v3 script"));
      };

      document.head.appendChild(stripe_script);
    });
  }

  function createLoadingPromise(name, loadingFn) {
    return {
      name,
      promise: (async () => {
        try {
          const result = await loadingFn();
          return result;
        } catch (error) {
          console.error(`Error loading ${name}:`, error);
          throw error;
        }
      })()
    };
  }

  async function loadUpIframeSettings(merlin_elem, query_params, open_immediately) {
    try {
      const base_config = merlinData(merlin_elem, query_params);
      const base_merlin_key = base_config.widget_key;

      if (!base_merlin_key) {
        const error = new Error("No widget key found in base config");
        Honeybadger.notify(error, {
          context: {
            message: "Missing widget key in base config",
            base_config,
            merlin_elem,
            query_params
          }
        });
        throw error;
      }

      const loadingTasks = [
        createLoadingPromise("Stripe", () => loadStripeV3()),
        createLoadingPromise("VisitorData", () => fetchConfigSettings(base_merlin_key)),
        createLoadingPromise("Iframe", async () => {
          merlinState.iframeWasClosed = false;
          return loadIframe(base_config);
        })
      ];

      try {
        // Execute all tasks in parallel but handle individual failures
        const results = await Promise.allSettled(loadingTasks.map(task => task.promise));
        
        // Check for failed tasks
        const failedTasks = results.filter(r => r.status === 'rejected');
        if (failedTasks.length > 0) {
          console.error("Some loading tasks failed:", failedTasks);
          
          // If Stripe failed but others succeeded, we can still proceed with limited functionality
          const stripeResult = results[0];
          const visitorResult = results[1];
          const iframeResult = results[2];
          
          if (iframeResult.status === 'rejected') {
            // If iframe failed, we can't proceed
            throw new Error("Failed to load critical iframe component");
          }
          
          if (visitorResult.status === 'rejected') {
            // If visitor data failed, we can't proceed
            throw new Error("Failed to load visitor configuration");
          }
          
          // If we get here, only Stripe failed - we can continue with limited payment options
          console.warn("Stripe loading failed - some payment methods may be unavailable");
        }

        // Extract successful results
        const [stripe, visitorResponse, iframeReady] = results.map(r => 
          r.status === 'fulfilled' ? r.value : null
        );

        const config_settings = buildFullConfig(visitorResponse, query_params, merlin_elem);
        merlinState.preloadedMerlinKey = base_merlin_key;

        if (open_immediately) {
          await openMerlin(config_settings).catch(err => {
            console.error("Failed to open Merlin:", err);
            throw err;
          });
        } else {
          const iframe = document.getElementById(MERLIN_IFRAME);
          if (!iframe) {
            throw new Error("Iframe not found after initialization");
          }
          await postRobot.send(iframe, "MERLIN_CONFIG", { config: config_settings })
            .catch(err => {
              console.error("Failed to send config to iframe:", err);
              throw err;
            });
        }

        // Only attempt payment setup if Stripe loaded successfully
        if (stripe && config_settings.payment_mode !== "stock" && config_settings.payment_mode !== "crypto") {
          const paymentSetupTasks = [
            createLoadingPromise("PaymentRequest", () => merlinState.getPaymentRequestReadyPromise()),
            createLoadingPromise("StripePayment", () => initiateStripePaymentRequest(config_settings))
          ];

          // Payment setup failures shouldn't block the widget from opening
          await Promise.allSettled(paymentSetupTasks.map(task => task.promise))
            .then(results => {
              const failures = results.filter(r => r.status === 'rejected');
              if (failures.length > 0) {
                console.warn("Some payment setup tasks failed:", failures);
              }
            });
        }

        return config_settings;
      } catch (taskError) {
        console.error("Critical initialization failed:", taskError);
        const error = new Error("Critical initialization failed");
        error.originalError = taskError;
        Honeybadger.notify(error, {
          context: {
            message: "Error in loading tasks",
            base_merlin_key,
            base_config,
            original_error: taskError.message
          }
        });
        throw error;
      }
    } catch (error) {
      console.error("Error in loadUpIframeSettings:", error);
      Honeybadger.notify(error, {
        context: {
          message: "Failed to load iframe settings",
          merlin_elem,
          query_params,
          open_immediately
        }
      });
      throw error;
    }
  }

  async function setupClickHandlers(query_params) {
    try {
      const merlin_links = document.getElementsByClassName("open-merlin");

      Array.from(merlin_links).forEach(link => {
        try {
          link.addEventListener("click", async function (e) {
            e.preventDefault();
            e.stopPropagation();

            try {
              if (merlinState.iframeWasClosed) {
                await loadUpIframeSettings(e.target, query_params, true);
              } else {
                const data = merlinData(e.target, query_params);
                await merlinState.getMerlinIframeReadyPromise();

                if (merlinState.preloadedMerlinKey === data.widget_key) {
                  openMerlin(data);
                } else {
                  const visitorResponse = await fetchConfigSettings(data.widget_key);
                  const config_settings = buildFullConfig(visitorResponse, query_params, e.target);
                  const iframe_tag = document.getElementById(MERLIN_IFRAME);
                  await postRobot.send(iframe_tag, "MERLIN_CONFIG", { config: config_settings });
                  openMerlin(data);
                }
              }
            } catch (clickHandlerError) {
              console.error("Error in click handler:", clickHandlerError);
            }
          });
        } catch (listenerError) {
          console.error("Error adding click listener:", listenerError);
        }
      });
    } catch (error) {
      console.error("Error in setupClickHandlers:", error);
      throw error; // Re-throw to be caught by the main try-catch
    }
  }

  async function initiateStripePaymentRequest(config_settings) {
    try {
      
      // Ensure we have a Stripe instance
      const stripe = await loadStripeV3();
      if (!stripe) {
        throw new Error("Stripe not initialized");
      }

      const request_address_on_apple_pay = merlinState.ACCOUNTS_FOR_APPLE_PAY_ADDRESS.includes(config_settings.account_id);
      
      const request_address = request_address_on_apple_pay;
      const request_shipping = request_address;
      const required_billing_contact_fields = request_address ? ["postalAddress"] : [];
      const shipping_options = request_address ? [{
        id: "free-shipping",
        label: "Mailing Address",
        detail: "Address for paper statements",
        amount: 0,
      }] : [];

      merlinState.paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: config_settings.account_name || "Church",
          amount: 1000,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestShipping: request_shipping,
        shippingOptions: shipping_options,
        requiredBillingContactFields: required_billing_contact_fields,
      });

      // Set up payment request event handlers
      merlinState.paymentRequest.on("token", async function (ev) {
        try {
          const name_array = ev.payerName.split(/\s+/);
          const first_name = name_array.slice(0, -1).join(" ");
          const last_name = name_array.pop();
          const shipping_address = parseShippingAddress(ev.shippingAddress);
          const stripe_payment_token_type = `payment_request_${ev.token.type}`;
          const email = ev.payerEmail;
          const token = ev.token.id;

          const success_response = {
            first_name,
            last_name,
            stripe_payment_token_type,
            email,
            token,
            address: shipping_address,
          };

          const iframe = document.getElementById(MERLIN_IFRAME);
          if (!iframe) {
            throw new Error("Iframe not found when handling payment token");
          }

          await postRobot.send(iframe, "paymentRequestResponse", success_response);
          ev.complete("success");
        } catch (err) {
          console.error("Error handling payment token:", err);
          ev.complete("fail");
        }
      });

      // Check if payment can be made
      const canMakePaymentResult = await merlinState.paymentRequest.canMakePayment();
      console.log("Payment method capabilities:", {
        applePay: canMakePaymentResult?.applePay,
        googlePay: canMakePaymentResult?.googlePay
      });
      
      merlinState.canMakePayment = !!canMakePaymentResult;

      const iframe = document.getElementById(MERLIN_IFRAME);
      if (!iframe) {
        throw new Error("Iframe not found when sending payment request availability");
      }

      // Send the result to the iframe
      return postRobot.send(iframe, "paymentRequestAvailable", canMakePaymentResult);
    } catch (error) {
      console.error("Error in payment request setup:", error);
      Honeybadger.notify(error, {
        context: {
          message: "Payment request setup failed",
          config_settings,
          error_details: error.message
        }
      });
      throw error;
    }
  }

  function closeIframe() {
    const iframe = document.getElementById(MERLIN_IFRAME);
    iframe.parentNode.removeChild(iframe);
    resetParentViewportSettings();
    merlinState.iframeWasClosed = true;
    document.body.classList.remove("modal-open");
    merlinState.resetMerlinIframeReadyPromise();
    const close_button = document.getElementById("merlin-close-button");
    close_button.parentNode.removeChild(close_button);
  }

  // Event handlers
  postRobot.on("closeModal", () => {
    closeIframe();
    return { success: true };
  });

  postRobot.on("reloadParent", () => {
    window.location.reload();
    return { success: true };
  });

  postRobot.on("3ds-authentication-complete", () => {
    console.log("parent page 3ds-authentication-complete");
  });

  postRobot.on("getUTMParams", () => {
    const utm_params = window.location.search.substring(1);
    return utm_params.split("&").filter((param) => {
      return param.startsWith("utm_") || param.startsWith("sc");
    });
  });

  postRobot.on("transactionSuccessful", (event) => {
    const txnSuccessful = new CustomEvent("transactionSuccessful", {
      detail: {
        amount_cents: event.data.amount_cents,
        transaction_id: event.data.transaction_id,
        first_name: event.data.first_name,
        last_name: event.data.last_name,
        gave_logged_in: event.data.gave_logged_in,
      },
    });

    merlinState.paymentRequest = null;
    merlinState.transactionSuccessful = null;
    document.dispatchEvent(txnSuccessful);
    return true;
  });

  postRobot.on("clickedPaymentRequest", () => {
    merlinState.paymentRequest.show();
  });

  postRobot.on("VISITOR_DATA_COMPLETE", () => {
    console.console.timeEnd("FINAL_LOAD");
  });

  postRobot.on("updateAmountPaymentRequest", (event) => {
    if (merlinState.paymentRequest) {
      merlinState.paymentRequest.update({
        total: {
          label: event.data.account_name,
          amount: event.data.total_amount_cents,
        },
      });
    }
  });
})();